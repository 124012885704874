import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import "./custom.css";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const ForgotPassword = React.lazy(() =>
  import("./views/Pages/Login/ForgetPassword.js")
);
const Process = React.lazy(() => import("./views/Pages/Login/process"));
const Welcome = React.lazy(() => import("./views/FrontWeb/Home"));

const PrivacyPolicyFront = React.lazy(() =>
  import("./views/FrontWeb/PrivacyPolicy")
);

const FaqFront = React.lazy(() => import("./views/FrontWeb/Faq"));

const AboutUsFront = React.lazy(() => import("./views/FrontWeb/Aboutus"));
const Inquiry = React.lazy(() => import("./views/FrontWeb/Inquiry"));

class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <React.Suspense fallback={loading()}>
          <Switch>
            {/* <Route
              exact
              path="/"
              render={() => {
                return <Redirect to="/dashboard" />;
              }}
            /> */}
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/payment-process-checking"
              name="Process"
              render={() => <Process />}
            />
            {/* <Route
              path="/home"
              exact
              name="dd"
              render={(props) => <Welcome {...props} />}
            /> */}
            {/* <Route
              path="/inquiry"
              exact
              name="dd"
              render={(props) => <Inquiry {...props} />}
            /> */}

            {/* <Route
              path="/privacy-policy"
              exact
              name="dd"
              render={(props) => <PrivacyPolicyFront {...props} />}
            /> */}
            {/* <Route
              path="/faq"
              exact
              name="dd"
              render={(props) => <FaqFront {...props} />}
            /> */}
            {/* <Route
              path="/about-us"
              exact
              name="dd"
              render={(props) => <AboutUsFront {...props} />}
            /> */}
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
